import { FC, useEffect, useRef, useState } from "react";
import { IDiffExtendedItemWithGroup, ILineBreak, ISelection, ITranscriptExtended } from "./types";
import { collectBeforeAndAfterWordsForSelectionItem, mergeDiffItemsWithByGroupId, qaIssuesCount } from "./utils";
import ArticleQaText from "./ArticleQaText";

import ArticleQaWords from "./ArticleQaWords";
import AudioPlayer from "react-h5-audio-player";
import { ArticleQaWrapper, BottomActions, ControlsWrapper, QaPlayerSpeedButton } from "./styles";
import ToggleSwitch from "src/components/ToggleSwitch";
// import { TextButton } from "src/styles/TextButton";
import { ButtonV2 } from "src/styles/ButtonV2";
import IArticle from "src/types/article";

interface IProps {
    transcript: ITranscriptExtended;
    article?: IArticle;
    lineBreaks: ILineBreak[];
    defaultSelections?: ISelection[];
    saveTranscriptionSelections: (selections: ISelection[]) => void;
}

const TranscriptionQa: FC<IProps> = ({ transcript, article, lineBreaks, defaultSelections, saveTranscriptionSelections }) => {
    const [selections, setSelections] = useState<ISelection[]>(defaultSelections || []);

    const [diffFiltered, setDiffFiltered] = useState<IDiffExtendedItemWithGroup[]>([]);

    // const [isQaStarted, setIsQaStarted] = useState(false);
    // const [isQaCompleted, setIsQaCompleted] = useState(false);

    const [showSubstitutions, setShowSubstitutions] = useState(false);
    const [showInsertions, setShowInsertions] = useState(true);
    const [showDeletions, setShowDeletions] = useState(true);
    const [showLineBreaks, setShowLineBreaks] = useState(false);
    const [isSensitive, setIsSensitive] = useState(false);
    const [shouldAutoPlay, setShouldAutoPlay] = useState(true);

    const [currentAudioTime, setCurrentAudioTime] = useState<number | null>(null);

    const qaTextWrapperRef = useRef<HTMLDivElement>(null);
    const qaWordsWrapperRef = useRef<HTMLDivElement>(null);

    const [playerSpeed, setPlayerSpeed] = useState(1);
    const playerRef = useRef() as any;
    const audioTimeUpdateListener = useRef(null) as any;

    const [isAudioManuallySeeked, setIsAudioManuallySeeked] = useState(false);

    // const onClickNext = () => {
    //     setIsQaStarted(true);
    // };

    // const onClickGoBack = () => {
    //     window.close();
    // };

    const isSensitiveDiffItem = (item: IDiffExtendedItemWithGroup) => {
        if (isSensitive || !!item.groupId) return true;

        let value: string | null = null;

        if (item.type === "DELETION") {
            value = item.reference || null;
        } else if (item.type === "INSERTION") {
            value = item.hypothesis || null;
        }

        if (!value || value.trim().split(" ").length > 1) return true;

        return false;
    };

    const isSubstitutionAllowed = (item: IDiffExtendedItemWithGroup, nextItem?: IDiffExtendedItemWithGroup) => {
        /*
        Ignore:
        Any words with an apostrophe such as ‘s, 're, 've, 'll, 't, 'm, 'ts and so on. Basically, if a Substitution has an apostrophe in it, ignore it.

        The word “in” (…for some voices, it thinks it is substituted with “and” every time “in” appears)

        Any substitution where the original word includes a long dash like this one —. The issue is that it thinks the long dash is acting as a hyphen to join the two words, so it treats “…Austin—and…” in the following sentence as being one word: “But Austin—and Texas more generally…”. One option could be to remove any/all long dashes from the original text before submitting it to the STT engine.

        All instances of Substitutions followed immediately by an Insertion. 

        The word “percent”. It always treats this as a Deletion.
        */

        const { reference: itemReference, hypothesis } = item;

        if (itemReference?.includes("'") || itemReference?.includes("’") || itemReference?.includes("‘")) {
            return false;
        }

        if (itemReference && ["percent"].includes(itemReference.trim())) {
            return false;
        }

        if (nextItem && nextItem.type === "INSERTION") {
            return false;
        }

        if ((hypothesis === "in" && itemReference === "and") || (hypothesis === "and" && itemReference === "in")) {
            return false;
        }

        if ((hypothesis === "an" && itemReference === "and") || (hypothesis === "and" && itemReference === "an")) {
            return false;
        }

        if ((hypothesis === "our" && itemReference === "are") || (hypothesis === "are" && itemReference === "our")) {
            return false;
        }

        if ((hypothesis === "patients" && itemReference === "patience") || (hypothesis === "patients" && itemReference === "patience")) {
            return false;
        }

        if ((hypothesis === "think" && itemReference === "thank") || (hypothesis === "thank" && itemReference === "think")) {
            return false;
        }

        if ((hypothesis === "by" && itemReference === "buy") || (hypothesis === "buy" && itemReference === "by")) {
            return false;
        }

        if ((hypothesis === "tier" && itemReference === "tear") || (hypothesis === "tear" && itemReference === "tier")) {
            return false;
        }

        if ((hypothesis === "cary" && itemReference === "carry") || (hypothesis === "carry" && itemReference === "cary")) {
            return false;
        }

        if (itemReference === "noa" && ["noah", "noaa"].includes(hypothesis || "")) {
            return false;
        }

        // where the characters of a word with ‘n' characters matches ‘n-1’ or ‘n+1’ of the characters in the substituted word. For example, this should cater for words ending in ‘ed’ such as “outsize”/”outsized”; words ending in ‘s' such as “official”/”officials” and words with slightly different spelling such as “Clarke”/”Clark”. The reason for the ‘n-1’ OR ‘n+1’ is because sometimes the longer word will be the original and sometimes the shorter one will be the original.

        if ((hypothesis && hypothesis.substring(0, hypothesis.length - 1) === itemReference) || (itemReference && itemReference.substring(0, itemReference.length - 1) === hypothesis)) {
            return false;
        }

        // digit numbers read as written numbers (e.g., 6 six)
        const isHypothesisANumber = (hyp: string, ref: string) => {
            // Parse the reference to a number
            const refNum = parseInt(ref, 10);

            // Check if the reference is a valid number between 0 and 10
            if (isNaN(refNum) || refNum < 0 || refNum > 10) {
                return false;
            }

            const numberWords: { [key: number]: string } = {
                0: "zero",
                1: "one",
                2: "two",
                3: "three",
                4: "four",
                5: "five",
                6: "six",
                7: "seven",
                8: "eight",
                9: "nine",
                10: "ten",
                11: "eleven",
                12: "twelve",
                13: "thirteen",
                14: "fourteen",
                15: "fifteen",
                16: "sixteen",
                17: "seventeen",
                18: "eighteen",
                19: "nineteen",
                20: "twenty",
                30: "thirty",
                40: "forty",
                50: "fifty",
                60: "sixty",
                70: "seventy",
                80: "eighty",
                90: "ninety",
                100: "hundred",
            };

            // Get the English word of the reference number
            const referenceInWords = numberWords[refNum];

            // Check if the hypothesis exactly matches the reference word
            return hyp.toLowerCase() === referenceInWords.toLowerCase();
        };

        if (isHypothesisANumber(hypothesis || "", itemReference || "") || isHypothesisANumber(itemReference || "", hypothesis || "")) {
            return false;
        }

        return true;
    };

    const qaCompletionStatus = () => {
        if (!transcript) return false;

        const { insertionsCount: insertionsSelectedCount, deletionsCount: deletionsSelectedCount } = qaIssuesCount(selections);
        const { insertionsCount, deletionsCount } = qaIssuesCount(
            transcript.diff.filter((item, itemIndex) => {
                if (item.type === "SUBSTITUTION") {
                    if (isSensitive) return true;

                    return isSubstitutionAllowed(item, transcript.diff?.[itemIndex + 1]);
                }

                return isSensitiveDiffItem(item);
            }),
        );

        // when sensitivity is on, then do the exact match, otherwise the values could be greater because of enabling and selecting issues and then again disabling it.
        if (isSensitive) {
            if (insertionsSelectedCount === insertionsCount && deletionsSelectedCount === deletionsCount) return true;
        } else {
            if (insertionsSelectedCount >= insertionsCount && deletionsSelectedCount >= deletionsCount) return true;
        }

        return false;
    };

    const onComplete = () => {
        // downloadReport();
        saveTranscriptionSelections(selections);
    };

    const onPickup = (diffItem: IDiffExtendedItemWithGroup) => {
        setSelections((prev) => {
            if (!transcript) return prev;

            const groupId = diffItem.groupId;
            const instancesToInclude = !groupId ? [] : transcript.diff.filter((i) => i.groupId === groupId);

            const isItemAGroupItem = instancesToInclude.length > 1;

            // line is just a paragraph/some words before and after the word
            let startItemForLine = isItemAGroupItem ? instancesToInclude[0] : diffItem;
            let endItemForLine = isItemAGroupItem ? instancesToInclude[instancesToInclude.length - 1] : diffItem;
            const itemLine = ["INSERTION"].includes(startItemForLine.type) ? "" : collectBeforeAndAfterWordsForSelectionItem(transcript.diff, startItemForLine.id, endItemForLine.id);

            if (isItemAGroupItem) {
                const instancesToRemove = prev.filter((i) => i.groupId === groupId).map((i) => i.id);
                const prevCloned = [...prev.filter((i) => !instancesToRemove.includes(i.id)), ...instancesToInclude.map((i) => ({ ...i, selectionType: "PICKUP", line: itemLine } as ISelection))];

                return prevCloned;
            }

            const item: ISelection = { ...diffItem, selectionType: "PICKUP", line: itemLine };

            const prevCloned = [...prev.filter((i) => i.id !== diffItem.id), item];

            return prevCloned;
        });
    };

    const onIgnore = (diffItem: IDiffExtendedItemWithGroup) => {
        setSelections((prev) => {
            if (!transcript) return prev;

            const groupId = diffItem.groupId;
            const instancesToInclude = !groupId ? [] : transcript.diff.filter((i) => i.groupId === groupId).map((i) => ({ ...i, selectionType: "IGNORE" } as ISelection));

            if (instancesToInclude.length > 1) {
                const instancesToRemove = prev.filter((i) => i.groupId === groupId).map((i) => i.id);
                const prevCloned = [...prev.filter((i) => !instancesToRemove.includes(i.id)), ...instancesToInclude];

                return prevCloned;
            }

            const item: ISelection = { ...diffItem, selectionType: "IGNORE" };

            const prevCloned = [...prev.filter((i) => i.id !== diffItem.id), item];

            return prevCloned;
        });
    };

    const isItemDisabled = (item: IDiffExtendedItemWithGroup) => {
        const diff = diffFiltered;

        // This function find the index of the item and slice the diff using this index as `end` index and then reverse it\
        // then look for an item which is not selected, if found one, then it means there is an item behind the current which should be selected\
        // so return `true` to disable the current and activate the item which is needed to be selected.

        const itemIndex = diff.findIndex((dItem) => dItem.id === item.id);

        if (itemIndex === -1) {
            return true;
        }

        if (itemIndex === 0) {
            return false;
        }

        const diffSliced = diff.slice(0, itemIndex).reverse();
        const isAnyItemNotSelected = diffSliced.some((dsItem) => {
            return !selections.some((slec) => slec.id === dsItem.id);
        });

        if (isAnyItemNotSelected) return true;

        return false;
    };

    const seekAudioToSeconds = (startTimeSeconds: number, endTimeSeconds?: number | null, customOffset?: number) => {
        const audio = playerRef.current.audio.current;

        const offset = customOffset || 1.2;

        audio.currentTime = startTimeSeconds - offset;
        audio.play();

        if (audioTimeUpdateListener.current) {
            setIsAudioManuallySeeked(false);
            audio.removeEventListener("timeupdate", audioTimeUpdateListener.current);
        }

        audioTimeUpdateListener.current =
            endTimeSeconds && endTimeSeconds >= startTimeSeconds
                ? () => {
                      const currAudio = playerRef.current.audio.current;
                      if (currAudio.currentTime > endTimeSeconds + offset * 2) {
                          currAudio.pause();
                          setIsAudioManuallySeeked(false);
                      }
                  }
                : null;

        if (audioTimeUpdateListener.current) {
            setIsAudioManuallySeeked(true);
            audio.addEventListener("timeupdate", audioTimeUpdateListener.current);
        }
    };

    const seekAudioByGroupId = (groupId: string, customOffset?: number) => {
        if (!transcript || !groupId) return;

        const words = transcript.diff.filter((i) => i.groupId === groupId).sort((a, b) => a.groupItemPos! - b.groupItemPos!);
        const firstWord = words?.[0];
        const lastWord = words?.[words.length - 1];

        if (!firstWord || !lastWord) {
            console.log("seekAudioByGroupId -- First word or Last word not found");
        } else if (!firstWord.startTime) {
            console.log("seekAudioByGroupId -- startTime is falsy");
        } else {
            seekAudioToSeconds(firstWord.startTime, lastWord.endTime, customOffset);
        }
    };

    const onAudioPlay = () => {
        if (!isAudioManuallySeeked && !!audioTimeUpdateListener.current) {
            const currAudio = playerRef.current.audio.current;
            currAudio.removeEventListener("timeupdate", audioTimeUpdateListener.current);

            audioTimeUpdateListener.current = null;
        }
    };

    const incrementPlayerSpeed = (e?: any) => {
        if (e?.preventDefault) {
            e.preventDefault();
        }

        const playerAudio = playerRef.current.audio.current;
        if (playerAudio && playerAudio.playbackRate < 2) {
            const newSpeed = playerAudio.playbackRate + 0.25;
            playerAudio.playbackRate = newSpeed;
            setPlayerSpeed(newSpeed);
        }
    };

    const decrementPlayerSpeed = (e?: any) => {
        if (e?.preventDefault) {
            e.preventDefault();
        }

        const playerAudio = playerRef.current.audio.current;
        if (playerAudio && playerAudio.playbackRate > 0.25) {
            const newSpeed = playerAudio.playbackRate - 0.25;
            playerAudio.playbackRate = newSpeed;
            setPlayerSpeed(newSpeed);
        }
    };

    const getSelectionItemInfo = (item: IDiffExtendedItemWithGroup) => {
        const selectionItem = selections.find((slec) => slec.id === item.id);
        const isIgnored = !!selectionItem && selectionItem.selectionType === "IGNORE";
        const isPickedUp = !!selectionItem && selectionItem.selectionType === "PICKUP";
        const isDisabled = /* !isQaStarted ? false  : */ isItemDisabled(item);
        const isSelected = isPickedUp || isIgnored;
        const isActive = /* !isQaStarted ? false : */ !isSelected && !isDisabled;

        return { selectionItem, isIgnored, isPickedUp, isDisabled: false, isSelected, isActive };
    };

    const isGroupActive = (item: IDiffExtendedItemWithGroup) => {
        if (!item.groupId) return false;

        const groupItems = diffFiltered.filter((i) => i.groupId === item.groupId);
        return groupItems.some((i) => {
            const info = getSelectionItemInfo(i);
            return info.isActive;
        });
    };

    const getActiveItem = () => {
        const itemIndex = diffFiltered.findIndex((item) => getSelectionItemInfo(item).isActive);

        if (itemIndex !== -1) {
            return { index: itemIndex, item: diffFiltered[itemIndex] };
        }

        return undefined;
    };

    useEffect(() => {
        if (transcript) {
            const filtered = transcript.diff.filter((item, itemIndex) => {
                const itemType = item.type;

                if (itemType === "EQUAL") return false;

                if (itemType === "INSERTION") {
                    if (!showInsertions) return false;
                    return isSensitiveDiffItem(item);
                }

                if (itemType === "DELETION") {
                    if (!showDeletions) return false;
                    return isSensitiveDiffItem(item);
                }

                if (itemType === "SUBSTITUTION") {
                    if (!showSubstitutions) return false;
                    if (isSensitive) return true;

                    return isSubstitutionAllowed(item, transcript.diff?.[itemIndex + 1]);
                }

                if (itemType === "LINEBREAK" && !showLineBreaks) return false;

                return true;
            });

            setDiffFiltered(filtered);
        }
    }, [transcript, showInsertions, showDeletions, showSubstitutions, showLineBreaks, isSensitive]);

    useEffect(() => {
        const audioTimeIntervalId = setInterval(() => {
            const currentTime = playerRef?.current?.audio?.current?.currentTime || null;
            setCurrentAudioTime(currentTime);
        }, 20);

        return () => clearInterval(audioTimeIntervalId);
    }, []);

    const { insertionsCount, deletionsCount, substitutionsCount } = qaIssuesCount(mergeDiffItemsWithByGroupId(transcript.diff));
    const completionStatus = qaCompletionStatus();
    const pickupsCount = mergeDiffItemsWithByGroupId(selections.filter((item) => item.selectionType === "PICKUP")).length;

    return (
        <ArticleQaWrapper>
            <div style={{ padding: "0px 40px", width: "100%", transform: "translateY(-80px)", display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
                <div
                    ref={qaWordsWrapperRef}
                    style={{ minWidth: "420px", height: "100vh", overflow: "scroll", paddingLeft: "4px", paddingRight: "4px", paddingTop: "110px", marginRight: "50px", paddingBottom: "55vh" }}
                >
                    <ControlsWrapper>
                        <div className="ARTICLE_QA-audio-player">
                            <AudioPlayer
                                customAdditionalControls={[
                                    <QaPlayerSpeedButton style={{ marginTop: "-18px" }} onClick={decrementPlayerSpeed}>
                                        _
                                    </QaPlayerSpeedButton>,
                                    <span
                                        style={{
                                            width: "35px",
                                            fontFamily: "Poppins",
                                            color: "#5a5e5f",
                                            fontSize: "16px",
                                            margin: "0px 10px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                        }}
                                    >
                                        {playerSpeed}x
                                    </span>,
                                    <QaPlayerSpeedButton onClick={incrementPlayerSpeed}>+</QaPlayerSpeedButton>,
                                ]}
                                onPlay={onAudioPlay}
                                ref={playerRef}
                                style={{ width: "100%", marginBottom: "15px" }}
                                src={article?.audioUrl || article?.externalAudioUrl || ""}
                                onListen={(e) => {
                                    const currentTime = playerRef?.current?.audio?.current?.currentTime || null;
                                    setCurrentAudioTime(currentTime);
                                }}
                            />
                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridColumnGap: "20px" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", minWidth: "185px" }}>
                                <ToggleSwitch checked={showInsertions} onChange={(v) => setShowInsertions(v ? true : false)} />
                                <p style={{ marginLeft: "7px", fontSize: "14px", color: "#000000", fontWeight: "400", fontFamily: "Poppins" }}>Insertions ({insertionsCount})</p>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", minWidth: "185px" }}>
                                <ToggleSwitch checked={showDeletions} onChange={(v) => setShowDeletions(v ? true : false)} />
                                <p style={{ marginLeft: "7px", fontSize: "14px", color: "#000000", fontWeight: "400", fontFamily: "Poppins" }}>Deletions ({deletionsCount})</p>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", minWidth: "185px" }}>
                                <ToggleSwitch checked={showSubstitutions} onChange={(v) => setShowSubstitutions(v ? true : false)} />
                                <p style={{ marginLeft: "7px", fontSize: "14px", color: "#000000", fontWeight: "400", fontFamily: "Poppins" }}>Substitutions ({substitutionsCount})</p>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", minWidth: "185px" }}>
                                <ToggleSwitch checked={isSensitive} onChange={(v) => setIsSensitive(v ? true : false)} />
                                <p style={{ marginLeft: "7px", fontSize: "14px", color: "#000000", fontWeight: "400", fontFamily: "Poppins" }}>Sensitivity</p>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", minWidth: "185px" }}>
                                <ToggleSwitch checked={showLineBreaks} onChange={(v) => setShowLineBreaks(v ? true : false)} />
                                <p style={{ marginLeft: "7px", fontSize: "14px", color: "#000000", fontWeight: "400", fontFamily: "Poppins" }}>Line Breaks ({lineBreaks.length})</p>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", minWidth: "185px" }}>
                                <ToggleSwitch checked={shouldAutoPlay} onChange={(v) => setShouldAutoPlay(v ? true : false)} />
                                <p style={{ marginLeft: "7px", fontSize: "14px", color: "#000000", fontWeight: "400", fontFamily: "Poppins" }}>AutoPlay</p>
                            </div>
                        </div>
                    </ControlsWrapper>
                    <ArticleQaWords
                        qaWordsWrapperRef={qaWordsWrapperRef}
                        // isQaStarted={isQaStarted}
                        selections={selections}
                        onPickup={onPickup}
                        onIgnore={onIgnore}
                        diff={diffFiltered}
                        getSelectionItemInfo={getSelectionItemInfo}
                        seekAudioToSeconds={seekAudioToSeconds}
                        seekAudioByGroupId={seekAudioByGroupId}
                        shouldAutoPlay={shouldAutoPlay}
                        getActiveItem={getActiveItem}
                    />
                </div>

                <div ref={qaTextWrapperRef} style={{ height: "100vh", overflow: "scroll", paddingTop: "110px", paddingBottom: "57vh", width: "100%" }}>
                    <ArticleQaText
                        sttEngine={transcript.sttEngine}
                        transcriptId={transcript.id}
                        qaTextWrapperRef={qaTextWrapperRef}
                        diff={transcript.diff}
                        showSubstitutions={showSubstitutions}
                        showInsertions={showInsertions}
                        showDeletions={showDeletions}
                        showLineBreaks={showLineBreaks}
                        getSelectionItemInfo={getSelectionItemInfo}
                        isGroupActive={isGroupActive}
                        isSensitive={isSensitive}
                        getActiveItem={getActiveItem}
                        isSubstitutionAllowed={isSubstitutionAllowed}
                        seekAudioToSeconds={seekAudioToSeconds}
                        currentAudioTime={currentAudioTime}
                    />
                </div>

                <BottomActions>
                    {/* {!isQaStarted ? (
                        <>
                            <TextButton onClick={onClickGoBack}>Go back</TextButton>
                            <ButtonV2 onClick={onClickNext}>Next</ButtonV2>
                        </>
                    ) : ( */}
                    <>
                        <p>
                            {completionStatus ? (
                                pickupsCount === 0 ? (
                                    <span style={{ color: "#00875A" }}>Nice work! No issues picked up.</span>
                                ) : (
                                    <span style={{ color: "#00875A" }}>Article Script QA completed 🙌 ({pickupsCount} Pickups)</span>
                                )
                            ) : (
                                <span style={{ color: "#e8a019" }}>Selection required of Insertions & Deletions.</span>
                            )}
                        </p>

                        <ButtonV2 onClick={onComplete} disabled={!completionStatus}>
                            Next
                        </ButtonV2>
                    </>
                    {/* )} */}
                </BottomActions>
            </div>
        </ArticleQaWrapper>
    );
};

export default TranscriptionQa;
