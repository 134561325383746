import { FC, RefObject, useEffect } from "react";
import { IDiffExtendedItemWithGroup, ISelection } from "./types";
import toast from "react-hot-toast";
import { WordWrapper } from "./styles";

interface IProps {
    sttEngine: string;
    transcriptId: string;
    diff: IDiffExtendedItemWithGroup[];
    showSubstitutions: boolean;
    showInsertions: boolean;
    showDeletions: boolean;
    showLineBreaks: boolean;
    isSensitive: boolean;
    currentAudioTime: number | null;
    qaTextWrapperRef: RefObject<HTMLDivElement>;
    isGroupActive: (item: IDiffExtendedItemWithGroup) => boolean;
    getActiveItem: () => { index: number; item: IDiffExtendedItemWithGroup } | undefined;
    isSubstitutionAllowed: (item: IDiffExtendedItemWithGroup, nextItem?: IDiffExtendedItemWithGroup) => boolean;
    seekAudioToSeconds: (startTimeSeconds: number, endTimeSeconds?: number, customOffset?: number) => void;
    getSelectionItemInfo: (item: IDiffExtendedItemWithGroup) => {
        selectionItem: ISelection | undefined;
        isIgnored: boolean;
        isPickedUp: boolean;
        isDisabled: boolean;
        isSelected: boolean;
        isActive: boolean;
    };
}

const ArticleQaText: FC<IProps> = ({
    sttEngine,
    transcriptId,
    diff,
    showSubstitutions,
    showInsertions,
    showDeletions,
    isSensitive,
    showLineBreaks,
    qaTextWrapperRef,
    currentAudioTime,
    getSelectionItemInfo,
    isGroupActive,
    getActiveItem,
    isSubstitutionAllowed,
    seekAudioToSeconds,
}) => {
    const activeItem = getActiveItem();

    const checkSensitivity = (item: IDiffExtendedItemWithGroup) => {
        if (isSensitive || !!item.groupId) return true;

        let value: string | null = null;

        if (item.type === "DELETION") {
            value = item.reference || null;
        } else if (item.type === "INSERTION") {
            value = item.hypothesis || null;
        }

        if (!value || value.trim().split(" ").length > 1) return true;

        return false;
    };

    const playOnWordClick = (item: IDiffExtendedItemWithGroup) => {
        const { startTime } = item;

        if (!startTime) {
            toast("Timestamps are not available for this word.");
            return;
        }

        seekAudioToSeconds(startTime, undefined, 0.08);
    };

    const isCurrentWordActive = (item: IDiffExtendedItemWithGroup) => {
        const { startTime, endTime } = item;

        if (!startTime || !endTime || !currentAudioTime) {
            return false;
        }

        // endTime - startTime < 1: to handle the scenarios where there is a big gap between start and end time, this will prevent those false positives
        if (currentAudioTime > startTime && currentAudioTime < endTime) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        const wrapperItem = qaTextWrapperRef.current;
        const item = wrapperItem?.querySelector(".QA_WORD__active");
        if (wrapperItem && item) {
            wrapperItem.scrollTo({ top: (item as any).offsetTop + 20 - (wrapperItem.getBoundingClientRect().height - item.getBoundingClientRect().height) / 2, behavior: "smooth" });
        }
    }, [activeItem?.item?.id]);

    return (
        <p
            style={{
                color: "#000000",
                fontSize: "16px",
                lineHeight: "24px",
                background: "#fff",
                boxShadow: "0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 8px 12px 0px rgba(9, 30, 66, 0.15)",
                padding: "30px",
                paddingTop: "20px",
                fontFamily: "Poppins",
                maxWidth: "700px",
                margin: "0",
                borderRadius: "5px",
                width: "100%",
            }}
        >
            <span style={{ fontSize: "12px", color: "#727984" }}>
                <span style={{ fontWeight: 700, textTransform: "uppercase", marginRight: "12px" }}>{sttEngine}</span>
                <span style={{ fontWeight: 500 }}>{transcriptId}</span>
            </span>
            <br />
            <br />

            {diff.map((item, itemIndex) => {
                const itemType = item.type;

                const isWordActive = isCurrentWordActive(item);

                const { isActive } = getSelectionItemInfo(item);
                const groupActiveStatus = isGroupActive(item);
                const activeStatus = isActive || groupActiveStatus;

                let wordActiveStyles = {};

                if (isWordActive) {
                    wordActiveStyles = { color: "#fff", background: "#007fbc" };
                }

                let activeStyles: (color?: string) => { [key: string]: any } = () => ({});

                if (activeStatus) {
                    activeStyles = (color?: string) => ({ color, background: "#202020" });
                }

                if (itemType === "INSERTION" && !!showInsertions && checkSensitivity(item))
                    return (
                        <WordWrapper
                            onClick={() => playOnWordClick(item)}
                            className={activeStatus ? "QA_WORD__active" : ""}
                            style={{ cursor: "pointer", color: "#000000a8", fontWeight: 500, background: "rgb(206, 234, 214)", ...activeStyles("rgb(206, 234, 214)"), ...wordActiveStyles }}
                        >
                            <s>{`${item.isSpaceBefore === false ? "" : " "}${item.hypothesis}`}</s>
                        </WordWrapper>
                    );
                if (itemType === "DELETION" && !!showDeletions && checkSensitivity(item))
                    return (
                        <WordWrapper
                            onClick={() => playOnWordClick(item)}
                            className={activeStatus ? "QA_WORD__active" : ""}
                            style={{ cursor: "pointer", color: "#000000a8", fontWeight: 500, background: "rgb(250, 210, 207)", ...activeStyles("rgb(250, 210, 207)"), ...wordActiveStyles }}
                        >
                            {`${item.isSpaceBefore === false ? "" : " "}${item.reference}`}
                        </WordWrapper>
                    );
                if (itemType === "SUBSTITUTION" && !!showSubstitutions && (isSensitive ? true : isSubstitutionAllowed(item, diff?.[itemIndex + 1])))
                    return (
                        <WordWrapper
                            onClick={() => playOnWordClick(item)}
                            className={activeStatus ? "QA_WORD__active" : ""}
                            style={{ cursor: "pointer", color: "#000000a8", fontWeight: 500, background: "rgb(254, 239, 195)", ...activeStyles("rgb(254, 239, 195)"), ...wordActiveStyles }}
                        >
                            {`${item.isSpaceBefore === false ? "" : " "}`}
                            <u>{item.reference}</u>
                            <s>{item.hypothesis}</s>
                        </WordWrapper>
                    );

                if (itemType === "LINEBREAK") {
                    if (showLineBreaks) {
                        return (
                            <>
                                <br />
                                <br />
                                <span className={activeStatus ? "QA_WORD__active" : ""} style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                    <span style={{ marginBottom: "-2px", marginRight: "4px" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                            <path d="m560-120-57-57 144-143H200v-480h80v400h367L503-544l56-57 241 241-240 240Z" />
                                        </svg>
                                    </span>
                                    <span style={{ color: "000000a8", fontWeight: 500, ...activeStyles("rgb(225, 226, 255)") }}>Line Break</span>
                                </span>
                                <br />
                            </>
                        );
                    }

                    return (
                        <>
                            <br />
                            <br />
                        </>
                    );

                    // if (showLineBreaks) {
                    //     return (
                    //         <WordWrapper
                    //             onClick={() => playOnWordClick(item)}
                    //             className={activeStatus ? "QA_WORD__active" : ""}
                    //             style={{ cursor: "pointer", color: "000000a8", fontWeight: 500, background: "rgb(225, 226, 255)", ...activeStyles("rgb(225, 226, 255)"), ...wordActiveStyles }}
                    //         >
                    //             {" "}
                    //             {item.reference}{" "}
                    //         </WordWrapper>
                    //     );
                    // }
                    // return "";
                }

                return (
                    <WordWrapper onClick={() => playOnWordClick(item)} style={{ cursor: "pointer", ...wordActiveStyles }}>{`${item.isSpaceBefore === false ? "" : " "}${
                        item.reference || ""
                    }`}</WordWrapper>
                );
            })}
        </p>
    );
};

export default ArticleQaText;
