import { FC, RefObject, useEffect } from "react";
import { StyledQaWordCard } from "./styles";
import { formatTime, mergeDiffItemsWithByGroupId, truncateTheWordsText } from "./utils";
import { IDiffExtendedItemWithGroup, ISelection } from "./types";

interface IProps {
    diff: IDiffExtendedItemWithGroup[];
    onPickup: (item: IDiffExtendedItemWithGroup) => void;
    onIgnore: (item: IDiffExtendedItemWithGroup) => void;
    selections: ISelection[];
    // isQaStarted: boolean;
    qaWordsWrapperRef: RefObject<HTMLDivElement>;
    shouldAutoPlay: boolean;
    getActiveItem: () => { index: number; item: IDiffExtendedItemWithGroup } | undefined;
    seekAudioToSeconds: (startTimeSeconds: number, endTimeSeconds?: number, customOffset?: number) => void;
    seekAudioByGroupId: (groupId: string, customOffset?: number) => void;
    getSelectionItemInfo: (item: IDiffExtendedItemWithGroup) => {
        selectionItem: ISelection | undefined;
        isIgnored: boolean;
        isPickedUp: boolean;
        isDisabled: boolean;
        isSelected: boolean;
        isActive: boolean;
    };
}

const ArticleQaWords: FC<IProps> = ({
    diff,
    onPickup,
    onIgnore,
    selections,
    // isQaStarted,
    shouldAutoPlay,
    getSelectionItemInfo,
    seekAudioToSeconds,
    seekAudioByGroupId,
    qaWordsWrapperRef,
    getActiveItem,
}) => {
    useEffect(() => {
        const wrapperItem = qaWordsWrapperRef.current;
        const item = wrapperItem?.querySelector(".QA_CARD__active");
        if (wrapperItem && item) {
            wrapperItem.scrollTo({ top: (item as any).offsetTop + 20 - (wrapperItem.getBoundingClientRect().height - item.getBoundingClientRect().height) / 2, behavior: "smooth" });
        }

        // auto play
        if (shouldAutoPlay) {
            const item = getActiveItem();

            if (item) {
                const { item: activeItem, index: itemIndex } = item;
                if (itemIndex !== 0) {
                    let customOffset: number | undefined = undefined;
                    if (activeItem.lineBreakDiffItem) {
                        customOffset = 1.2;
                    }

                    if (activeItem.groupId) {
                        seekAudioByGroupId(activeItem.groupId, customOffset);
                    } else {
                        seekAudioToSeconds(activeItem.startTime!, activeItem.endTime!, customOffset);
                    }
                }
            }
        }
    }, [selections, diff]);

    const onPickupClick = (item: IDiffExtendedItemWithGroup) => (e: any) => {
        e.preventDefault();

        onPickup(item);
    };

    const onIgnoreClick = (item: IDiffExtendedItemWithGroup) => (e: any) => {
        e.preventDefault();

        onIgnore(item);
    };

    const diffMergedByGroupIds = mergeDiffItemsWithByGroupId(diff);

    return (
        <div>
            {diffMergedByGroupIds.map((item) => {
                const timeStamp = item.startTime;
                let word: string;
                let substitutedWord: string | undefined = undefined;
                let heading;

                if (item.type === "INSERTION") {
                    heading = (
                        <>
                            Word(s) <span style={{ padding: "0px 2px", color: "rgba(0, 0, 0, 0.7)", fontWeight: 400, background: "rgba(206, 234, 214, 0.5)" }}>inserted</span>
                        </>
                    );
                    word = item.hypothesis || "";
                } else if (item.type === "SUBSTITUTION") {
                    heading = (
                        <>
                            Word(s) <span style={{ padding: "0px 2px", color: "rgba(0, 0, 0, 0.7)", fontWeight: 400, background: "rgba(254, 239, 195, 0.5)" }}>substituted</span>
                        </>
                    );
                    word = item.reference || "";
                    substitutedWord = item.hypothesis || "";
                } else if (item.type === "LINEBREAK") {
                    heading = (
                        <>
                            <span style={{ padding: "0px 2px", color: "rgba(0, 0, 0, 0.8)", fontWeight: 500, display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                <span style={{ marginBottom: "-3px", marginLeft: "-3px", marginRight: "2px" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="rgba(0, 0, 0, 0.8)" height="20" viewBox="0 -960 960 960" width="20">
                                        <path d="m576-192-51-51 129-129H240v-444h72v372h342L525-573l51-51 216 216-216 216Z" />
                                    </svg>
                                </span>
                                Line Break
                            </span>
                        </>
                    );
                    word = `After ”${item.reference}”`;
                } else {
                    heading = (
                        <>
                            Word(s) <span style={{ padding: "0px 2px", color: "rgba(0, 0, 0, 0.7)", fontWeight: 400, background: "rgba(250, 210, 207, 0.5)" }}>deleted</span>
                        </>
                    );
                    word = item.reference || "";
                }

                const { isActive, isDisabled, isIgnored, isPickedUp, isSelected } = getSelectionItemInfo(item);

                let customOffset: number | undefined = undefined;
                if (item.lineBreakDiffItem) {
                    customOffset = 1.2;
                }

                return (
                    <StyledQaWordCard
                        className={isActive ? "QA_CARD__active" : ""}
                        isDisabled={isDisabled}
                        isIgnoreButtonActive={isIgnored}
                        isPickupButtonActive={isPickedUp}
                        isSelected={isSelected}
                        isActive={isActive}
                    >
                        <p className="QA_WORD__timestamp">{timeStamp !== null && timeStamp !== undefined ? formatTime(timeStamp * 1000) : "--"}</p>

                        <p className="QA_WORD__heading">{heading}</p>

                        <div className="QA_WORD__words_group">
                            <div>
                                <p className="QA_WORD__word_heading">WORD</p>
                                <p className="QA_WORD__word">{truncateTheWordsText(word)}</p>
                            </div>

                            {substitutedWord && (
                                <div>
                                    <p className="QA_WORD__word_heading">SUBSTITUTED</p>
                                    <p className="QA_WORD__word">{substitutedWord}</p>
                                </div>
                            )}
                        </div>

                        {/* {isQaStarted && ( */}
                        <div className="QA_WORD__action_buttons">
                            <button className="QA_WORD__action_buttons-pickup" disabled={isDisabled} onClick={onPickupClick(item)}>
                                Pickup
                            </button>
                            <button className="QA_WORD__action_buttons-ignore" disabled={isDisabled} onClick={onIgnoreClick(item)}>
                                Ignore
                            </button>
                            {timeStamp !== null && timeStamp !== undefined && (
                                <button
                                    className="QA_WORD__action_buttons-play"
                                    disabled={isDisabled}
                                    onClick={item.groupId ? () => seekAudioByGroupId(item.groupId!, customOffset) : () => seekAudioToSeconds(timeStamp, item.endTime!, customOffset)}
                                >
                                    Play
                                </button>
                            )}
                        </div>
                        {/* )} */}
                    </StyledQaWordCard>
                );
            })}
        </div>
    );
};

export default ArticleQaWords;
